import React from 'react'
import NotFound from '../../components/common/not-found/not-found'
import UserTemplate from '../../templates/user-template'

const NotFoundPage = () => {
  return (
    <UserTemplate>
      <NotFound/>
    </UserTemplate>
  )
}

export default NotFoundPage
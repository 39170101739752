export const settings = {
    apiURL : "https://carrental-v3-backend.herokuapp.com",
    siteName: "Let's Rental Car",
    siteUrl : "letsrentalcar.com",
    email : "support@letsrentalcar.com",
    phone1: "(999)-999-9999",
    phone2: "(999)-999-9999",
    address:"1500 McKinney St, Houston, TX 77010, USA",
    mapUrl: "https://goo.gl/maps/PdGRbWxoZgfubzUf6",
    mapEmbedUrl : "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d10575.660755926032!2d-95.37104619170077!3d29.74957699608172!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640bf2253364a25%3A0x8ed9f565fcbf923!2sDiscovery%20Green!5e0!3m2!1str!2str!4v1691058121437!5m2!1str!2str"
}